import React, { useCallback, useEffect, useState } from "react";
import styles from "./AgentsKnownManagement.module.scss";
import AgentsKnown from "../../widgets/AgentsKnown/AgentsKnown";
import axios from "../../api/axiosConfig";
import Modal from "../../components/Modal/Modal";
import ModalDelete from "../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
import { formatUptime } from "../../functions/FormatUptime/FormatUptime";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import FilterTabs from "../../components/FilterTabs/FilterTabs";

const AgentsKnownManagementView = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SingleAgentT[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<SingleAgentT>();
  const { id } = useSelector((state: AppState) => state.customerContextState);
  const { customer } = useSelector((state: AppState) => state.authorization);
  const [noData, setNoData] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      await axios("/configuration/agent", {
        params: {
          knownAgents: true,
          contextCustomerId: id ? id : customer.id,
        },
      }).then((res) => {
        if (res && res.status === 200) {
          if (res.data.length) {
            setNoData(false);
            setTableData(
              res.data.map((elem: SingleAgentT) => {
                return {
                  agent: {
                    id: elem.id ? elem.id : "",
                    name: elem.name ? elem.name : "",
                  },
                  clientName: elem.clientName ? elem.clientName : "",
                  status: elem.status ? elem.status : "",
                  location:
                    elem.street || elem.zipCode || elem.city || elem.country
                      ? `${elem.country}, ${elem.province}, ${elem.city}, ${elem.zipCode}, ${elem.street}`
                      : "",
                  alias: elem.alias ? elem.alias : "",
                  registrationDate: elem.registrationDate
                    ? elem.registrationDate
                    : "",
                  version: elem.version ? elem.version : "",
                  circuitId: elem.circuitId ? elem.circuitId : "",
                  lastConnection: elem.lastConnection
                    ? elem.lastConnection
                    : "",
                  uptime: elem.upTime ? formatUptime(elem.upTime) : "",
                  lastSuccessfulTest: elem.lastTest ? elem.lastTest : "",
                  description: elem.description ? elem.description : "",
                };
              })
            );
          } else {
            setNoData(true);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, customer.id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  const openDelete = (agent: SingleAgentT) => {
    setSelectedAgent(agent);
    setModalOpen(true);
  };

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Agents" />
        {customer.mainCustomer && (
          <FilterTabs
            links={[
              {
                title: "Unknown Agents",
                path: "/agents-management/unknown",
              },
              {
                title: "Known Agents",
                path: "/agents-management/known",
              },
            ]}
          />
        )}
        <AgentsKnown data={tableData} openDelete={openDelete} noData={noData} />
        {noData && (
          <NoDataPlaceholder
            image
            title="No agents connected yet"
            subTitle="Connect your first agent by contacting support"
          />
        )}
      </Container>
      {selectedAgent && (
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
          <ModalDelete
            name="Agent"
            endpoint={`/configuration/agent/${selectedAgent.agent.id}`}
            setModalOpen={setModalOpen}
            handleUpdateData={fetchData}
          >
            <ModalDeleteElem>
              <div>
                <span className={styles.name}>{selectedAgent.agent.name}</span>
                <span className={styles.id}>ID: {selectedAgent.agent.id}</span>
              </div>
            </ModalDeleteElem>
          </ModalDelete>
        </Modal>
      )}
    </>
  );
};

export default AgentsKnownManagementView;

export type SingleAgentT = {
  id: string;
  name: string;
  clientName: string;
  status: string;
  country: string;
  province: string;
  zipCode: string;
  city: string;
  street: string;
  alias: string;
  registrationDate: number;
  version: string;
  circuitId: string;
  lastConnection: number;
  upTime: number;
  lastTest: number;
  description: string;
  agent: {
    id: string;
    name: string;
  };
};
