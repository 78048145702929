import React, { useState, useEffect, useCallback, useMemo } from "react";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import Panel from "../../../components/Panel/Panel";
import Heading from "../../../components/Heading/Heading";
import Label from "../../../components/Label/Label";
import styles from "./SingleAgentForm.module.scss";
import SelectInput from "../../../components/formElements/SelectInput/SelectInput";
import { Form, Formik } from "formik";
import AddApp from "../../../components/formElements/AddApp/AddApp";
import AddDescription from "../../../components/formElements/AddDescription/AddDescription";
import ConnectionDetails from "../../../components/ConnectionDetails/ConnectionDetails";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import Features from "../../../components/formElements/Features/Features";
import axios from "../../../api/axiosConfig";
import ModalSuccess from "../../../components/Modal/ModalSuccess/ModalSuccess";
import CurrencyInput from "../../../components/formElements/CurrencyInput/CurrencyInput";
import { SingleAgentT } from "../SingleAgent.view";
import AgentLocationGroupSelect from "../../../components/formElements/AgentLocationGroupSelect/AgentLocationGroupSelect";
import ArrowControls from "../../../components/ArrowControls/ArrowControls";
import CustomersSelect from "../../../components/formElements/CustomersSelect/CustomersSelect";

const SingleAgentForm = (props: ISingleAgentForm) => {
  const { data, handleUpdateData } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [networkDataNumber, setNetworkDataNumber] = useState<number>(0);

  // const { customer } = useSelector((state: AppState) => state.authorization);
  // const { id } = useSelector((state: AppState) => state.customerContextState);
  // const activeContext = id !== customer.id;

  const options = useMemo(() => [
    { value: 288, label: "5 mins" },
    { value: 144, label: "10 mins" },
    { value: 96, label: "15 mins" },
    { value: 48, label: "30 mins" },
    { value: 32, label: "45 mins" },
    { value: 24, label: "1 hour" },
    { value: 8, label: "3 hours" },
    { value: 4, label: "6 hours" },
    { value: 2, label: "12 hours" },
    { value: 1, label: "24 hours" },
  ],[])

  const frequencyFeatures = useCallback((defaultValue: number) => {
    const index = options.findIndex((option: any) => option.value === defaultValue);
    return options[index]?.label;
  }, [options])

  const handleNext = () => {
    networkDataNumber < data.network.length - 1 &&
      setNetworkDataNumber(networkDataNumber + 1);
  };
  const handleBack = () => {
    networkDataNumber !== 0 && setNetworkDataNumber(networkDataNumber - 1);
  };

  const initialValues = {
    name: data.name,
    locationGroup: data.locationGroup,
    country: data.country,
    province: data.province,
    zipCode: data.zipCode,
    city: data.city,
    street: data.street,
    alias: data.alias,
    description: data.description,
    applications: data.applications,
    numberOfTests: data.numberOfTests,
    agentType: data.agentType,
    clientId: data.clientName,
    frequencyOfFeatures: frequencyFeatures(data.frequencyOfFeatures),
    features: data.features.map((elem) => elem.id),
    wifiPassword: data.wifiPassword,
    wifiSSID: data.wifiSSID,
    currency: data.currency,
    employeeCost: data.employeeCost,
    bandwidthCost: data.bandwidthCost,
    agentDowntimeCost: data.agentDowntimeCost,
    locationDowntimeCost: data.locationDowntimeCost,
  };

  const handleSubmit = useCallback(
    (values, id) => {
      const info = {
        name: values.name,
        locationGroup: values.locationGroup,
        country: values.country,
        province: values.province,
        zipCode: values.zipCode,
        city: values.city,
        street: values.street,
        alias: values.alias,
        description: values.description,
        applications: values.applications,
        numberOfTests: values.numberOfTests,
        agentType: values.agentType,
        clientId: values.clientId === data.clientName ? data.clientId : values.clientId,
        frequencyOfFeatures:
        values.frequencyOfFeatures ===
        frequencyFeatures(data.frequencyOfFeatures)
          ? data.frequencyOfFeatures
          : values.frequencyOfFeatures,
        features: values.features,
        wifiPassword: values.wifiPassword,
        wifiSSID: values.wifiSSID,
        currency: values.currency,
        employeeCost: values.employeeCost,
        bandwidthCost: values.bandwidthCost,
        agentDowntimeCost: values.agentDowntimeCost,
        locationDowntimeCost: values.locationDowntimeCost,
      };
      axios
        .patch(`/configuration/agent/${id}/update`, info, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 204) {
            setModalOpen(true);
            handleUpdateData();
          }
        });
    },
    [handleUpdateData, data.clientId, data.clientName, data.frequencyOfFeatures, frequencyFeatures]
  );

  useEffect(() => {
    if (modalOpen) {
      const timer = () =>
        setTimeout(() => {
          setModalOpen(false);
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [modalOpen]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values, data.id);
          }, 500);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <LayoutHeader pathBack="/agents-management/known" title={data.name}>
              <Button
                title="Save"
                typeAttr={"submit"}
                icon={<IconButton />}
                icon_position="right"
              />
            </LayoutHeader>
            <Panel className={styles.panel}>
              <Heading
                title="General"
                className={styles.sectionTitle}
                weight="medium"
              />
              <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Group>
                    <Label title="Name" />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="name"
                      placeholder="Enter name"
                      required
                    />
                  </Group>
                  <Row>
                    <Column className="col-12 col-sm-6 pr-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="country"
                        label="Country"
                        placeholder="Enter Country"
                        required
                      />
                    </Column>
                    <Column className="col-12 col-sm-6 pl-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="province"
                        label="State/Province/Region"
                        placeholder="Enter Region"
                        required
                      />
                    </Column>
                    <Column className="col-12 col-sm-6 pr-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="zipCode"
                        label="Postal Code"
                        placeholder="Enter Postal Code"
                        required
                      />
                    </Column>
                    <Column className="col-12 col-sm-6 pl-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="city"
                        label="City"
                        placeholder="Enter City"
                        required
                      />
                    </Column>
                    <Column className="col-12">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="street"
                        label="Street"
                        placeholder="Enter Street, suite, apt."
                        required
                      />
                    </Column>
                  </Row>
                  <Group>
                    <Label title="Location group" />
                    <AgentLocationGroupSelect
                      id={data.clientId}
                      touched={touched}
                      errors={errors}
                      name="locationGroup"
                      className={styles.select}
                      required
                    />
                  </Group>
                  <Group>
                    <Label title="Alias" />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="alias"
                      placeholder="Enter Alias"
                      required
                    />
                  </Group>
                  <Group>
                    <Label title="Description" />
                    <AddDescription
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      name="description"
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Registration Date" />
                        <Heading
                          title={
                            data.registrationDate ? data.registrationDate : "-"
                          }
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Type of agent" />
                        <SelectInput
                          placeholder="Select type"
                          touched={touched}
                          errors={errors}
                          name="agentType"
                          options={[
                            { value: "user agent", label: "user agent" },
                            {
                              value: "enterprise agent",
                              label: "enterprise agent",
                            },
                            { value: "cloud agent", label: "cloud agent" },
                          ]}
                          required
                        />
                      </Group>
                    </Column>
                    {/* <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Number of snmp devices" />
                        <Heading title="15" Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column> */}
                    <Column className="col-12 col-lg-12">
                        <CustomersSelect
                          touched={touched}
                          errors={errors}
                          name="clientId"
                          label="Client Name"
                          required
                        />                   
                    </Column>
                  </Row>
                  <Group>
                    <Label title="Apps to test" />
                    <AddApp
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      name="applications"
                      title="Select for testing"
                    />
                  </Group>
                  {/* <Group>
                    <Label title="Number of application tests" />
                    <SelectInput
                      touched={touched}
                      errors={errors}
                      name="numberOfTests"
                      options={NumSelectOptionsScope(1, 100)}
                      placeholder="How many tests needed?"
                      isSearchable={false}
                      required
                      className={styles.select}
                    />
                  </Group> */}
                  <Group>
                    <Label title="Frequency of features" />
                    <SelectInput
                      touched={touched}
                      errors={errors}
                      name="frequencyOfFeatures"
                      options={options}
                      placeholder="How many tests needed?"
                      isSearchable={false}
                      required
                      className={styles.select}
                    />
                  </Group>
                  <Group>
                    <Label title="Features" />
                    <Features
                      name="features"
                      touched={touched}
                      errors={errors}
                      direction="row"
                    />
                  </Group>
                </Column>
              </Row>
              <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <div className="d-flex justify-content-between">
                    <Heading
                      title={
                        data.network.length > 1
                          ? `Network (${data.network.length})`
                          : "Network"
                      }
                      className={styles.sectionTitle}
                      weight="medium"
                    />
                    {data.network.length > 1 && (
                      <ArrowControls
                        currentItemNumber={networkDataNumber}
                        itemsLength={data.network.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    )}
                  </div>
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Connection" />
                        <Heading
                          title={data.network[networkDataNumber]?.connection}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Device name" />
                        <Heading
                          title={data.network[networkDataNumber]?.deviceName}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Connection name" />
                        <Heading
                          title={
                            data.network[networkDataNumber]?.connectionName
                          }
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Type" />
                        <Heading
                          title={
                            data.network[networkDataNumber]?.connectionType
                          }
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Connection status" />
                        <ConnectionDetails
                          level={
                            data.network[networkDataNumber]?.connectionDetails
                          }
                          type={data.network[networkDataNumber]?.connection}
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Transmit rate (Mbps)" />
                        <Heading
                          title={data.network[networkDataNumber]?.txRate}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>

                    {data.network[networkDataNumber]?.connection === "WiFi" && (
                      <>
                        <Column className="col-12 col-lg-6">
                          <Group>
                            <Label title="RSSI" />
                            <Heading
                              title={data.network[networkDataNumber]?.rssi}
                              Tag="h4"
                              weight="semi-bold"
                            />
                          </Group>
                        </Column>
                        <Column className="col-12 col-lg-6">
                          <Group>
                            <Label title="Noise" />
                            <Heading
                              title={data.network[networkDataNumber]?.noise}
                              Tag="h4"
                              weight="semi-bold"
                            />
                          </Group>
                        </Column>
                        <Column className="col-12 col-lg-6">
                          <Group>
                            <Label title="Channel" />
                            <Heading
                              title={data.network[networkDataNumber]?.channel}
                              Tag="h4"
                              weight="semi-bold"
                            />
                          </Group>
                        </Column>
                        <Column className="col-12 col-lg-6">
                          <Group>
                            <Label title="Mode" />
                            <Heading
                              title={data.network[networkDataNumber]?.mode}
                              Tag="h4"
                              weight="semi-bold"
                            />
                          </Group>
                        </Column>
                        <Column className="col-12 col-lg-6">
                          <Group>
                            <Label title="BSSID" />
                            <Heading
                              title={data.network[networkDataNumber]?.bssid}
                              Tag="h4"
                              weight="semi-bold"
                            />
                          </Group>
                        </Column>
                        <Column className="col-12 col-lg-6">
                          <Group>
                            <Label title="SSID name" />
                            <Heading
                              title={data.network[networkDataNumber]?.ssid}
                              Tag="h4"
                              weight="semi-bold"
                            />
                          </Group>
                        </Column>
                      </>
                    )}
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Internal IP" />
                        <Heading
                          title={data.network[networkDataNumber]?.internalIp}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="External IP" />
                        <Heading
                          title={data.network[networkDataNumber]?.ip}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Uptime" />
                        <Heading
                          title={data.uptime}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                  </Row>
                </Column>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Heading
                    title="System Info"
                    className={styles.sectionTitle}
                    weight="medium"
                  />
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="CPU" />
                        <Heading
                          title={data.network[networkDataNumber]?.cpu}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Memory" />
                        <Heading
                          title={data.network[networkDataNumber]?.cpuUsage}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="OS Type" />
                        <Heading
                          title={data.network[networkDataNumber]?.osName}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="OS Version" />
                        <Heading
                          title={data.network[networkDataNumber]?.osVersion}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Internal system name" />
                        <Heading
                          title={data.network[networkDataNumber]?.systemName}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Agent Version" />
                        <Heading
                          title={data.network[networkDataNumber]?.agentVersion}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                  </Row>
                </Column>
              </Row>
              {/* <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Heading
                    title="Advanced Settings"
                    className={styles.sectionTitle}
                    weight="medium"
                  />
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Wi-fi password" />
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="wifiPassword"
                          placeholder="Wi-fi password"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Wi-fi SSID" />
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="wifiSSID"
                          placeholder="Wi-fi SSID"
                        />
                      </Group>
                    </Column>
                  </Row>
                </Column>
              </Row> */}
              <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12">
                  <Heading
                    title="Financial"
                    className={styles.sectionTitle}
                    weight="medium"
                  />
                  <Row>
                    <Column className="col-12 col-sm-3">
                      <Group>
                        <Label title="Currency" />
                        <SelectInput
                          options={[
                            { value: "GBP", label: "GBP" },
                            { value: "USD", label: "USD" },
                            { value: "EUR", label: "EUR" },
                            { value: "AED", label: "AED" },
                            { value: "AUD", label: "AUD" },
                            { value: "JPY", label: "JPY" },
                            { value: "CHF", label: "CHF" },
                            { value: "CAD", label: "CAD" },
                            { value: "CNH", label: "CNH" },
                            { value: "INR", label: "INR" },
                          ]}
                          placeholder="Choose currency"
                          touched={touched}
                          errors={errors}
                          name="currency"
                          className={styles.select}
                        />
                      </Group>
                    </Column>
                  </Row>
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Employee cost of downtime / min" />
                        <CurrencyInput
                          className={styles.input}
                          touched={touched}
                          errors={errors}
                          name="employeeCost"
                          placeholder="Enter cost"
                          setFieldValue={setFieldValue}
                          required
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Bandwidth cost / mb" />
                        <CurrencyInput
                          className={styles.input}
                          touched={touched}
                          errors={errors}
                          name="bandwidthCost"
                          placeholder="Enter cost"
                          setFieldValue={setFieldValue}
                          required
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Agent cost od downtime / min" />
                        <CurrencyInput
                          className={styles.input}
                          touched={touched}
                          errors={errors}
                          name="agentDowntimeCost"
                          placeholder="Enter cost"
                          setFieldValue={setFieldValue}
                          required
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Location cost od downtime / min" />
                        <CurrencyInput
                          className={styles.input}
                          touched={touched}
                          errors={errors}
                          name="locationDowntimeCost"
                          placeholder="Enter cost"
                          setFieldValue={setFieldValue}
                          required
                        />
                      </Group>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Panel>
          </Form>
        )}
      </Formik>
      <ModalSuccess
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        text="Agent has been successfully updated"
      />
    </>
  );
};

export default SingleAgentForm;

const Group = (props: { children: Array<React.ReactChild> }) => (
  <div className={styles.group}>{props.children}</div>
);

interface ISingleAgentForm {
  data: SingleAgentT;
  handleUpdateData: () => void;
}
